import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';

import baseWretch from "wretch";
import QueryStringAddon from "wretch/dist/addons/queryString";


import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'form',
    'query',
    'querys',
    'center',
    'type',
    'dateRange',
    'day',
    'locationType',
    'distance',
    'audience',
    'sortBy',
    'initialContent',
    'locationFoundContent',
    'locationNotFoundContent',
    'listTab',
    'results',
    'city',
    'region',
    'postalCode',
    'country',
    'searchType',
    'searchFilters'
  ];

  connect() {
    window.geocoder = new MapboxGeocoder({
      accessToken: this.querysTarget.dataset.mapboxApiKey,
      placeholder: 'Postal code or city/state',
      proximity: 'ip',
      types: 'country,region,place,postcode,locality,neighborhood',
    });
    
    this.subscribe('search:filter', data => this.applyFilter(data));
    this.configureAutocomplete(geocoder);

    document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].addEventListener('input', function (evt) {
      var wrapper = document.getElementsByClassName('suggestions-wrapper')[0]
      wrapper.style.display = "block";
    });

    document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].value = this.queryTarget.value;

    this.setDataLayer(null)
  }

  setDataLayer(values) {
    window.dataLayer = window.dataLayer || [];

    var resultsReturn = 0
    var searchType = 'geo';

    if ( document.getElementById('no-results') == null ) {
      resultsReturn = document.getElementsByClassName('group-search-result').length
    }

    if ( this.searchTypeTarget.value == 'find' ) { 
      searchType = 'new'
      if ( location.search.length == 0 ) { searchType = 'previous' }
    }
    
    if ( values == null ) { values = this.formValues() }

    dataLayer.push({
      'event': 'find',
      'searchType': searchType,
      'search': document.getElementById('query').value,
      'results': resultsReturn,
      'path': window.location.pathname,
      'center': values.center,
      'dateRange': values.dateRange,
      'day': values.day,
      'distance': values.distance,
      'audience': values.audience,
      'locationType': values.locationType,
      'sortBy': values.sortBy,
      'type': values.type,
      'city': values.city,
      'region': values.region,
      'postalCode': values.postalCode,
      'country': values.country,
    });
  }

  configureAutocomplete(geocoder) {
    geocoder.addTo(this.querysTarget);
    this.handleGeocoder();
  }

  next() {
    var geocoderInput = '';
    geocoderInput =  document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].value;
    geocoder.query(geocoderInput);

    this.handleGeocoder();
    
    var wrapper = document.getElementsByClassName('suggestions-wrapper')[0]
    wrapper.style.display = "none";    
  }

  handleGeocoder() {
    geocoder.on('result', (e) => {
      const mapboxQuery = this.queryTarget;
      const searchlatString = JSON.stringify(e.result.center[1], null, 2);
      const searchlonString = JSON.stringify(e.result.center[0], null, 2);
      const searchplaceString = JSON.stringify(e.result.place_name, null, 2);

      var city = ''
      var region = '' 
      var postal_code = ''
      var country = ''

      if ( e.result.context ) {
        e.result.context.forEach(function (item) {
          if ( item.id.includes('place') ) { city = item.text }
          if ( item.id.includes('region') ) { region = item.text }
          if ( item.id.includes('postcode') ) { postal_code = item.text }
          if ( item.id.includes('country') ) { country = item.text }
        });
      }

      if ( e.result.place_type[0] == "place" ) {
        this.cityTarget.value = e.result.text
        this.postalCodeTarget.value = postal_code
      } else if ( e.result.place_type[0] == "postcode" ) {
        this.cityTarget.value = city
        this.postalCodeTarget.value = e.result.text
      } else {
        this.cityTarget.value = city  
        this.postalCodeTarget.value = postal_code
      }
      
      this.regionTarget.value = region
      this.countryTarget.value = country
      this.data.set('lat', searchlatString);
      this.data.set('lng', searchlonString);
      mapboxQuery.value = searchplaceString.slice(1, -1);
      
      this.submit();
    });
  }

  handleInput() {
    this.centerTarget.value = '';
    this.data.set('lat', '');
    this.data.set('lng', '');

    this._hideAutocomplete = /\d/.test(this.queryTarget.value);
    if (this._hideAutocomplete) this._autocomplete.close();
  }

  submit(event) {
    if (event) event.preventDefault();

    this.searchTypeTarget.value = 'find';

    if (this.data.get('lat')) {
      const lat = this.data.get('lat');
      const lng = this.data.get('lng');
      this.changeLocation(lat, lng);
      this.fetchResults();
    } else {
      const search = this.queryTarget.value;
      const isUSZip = /^\s*\d{5}\s*$/.test(search);
      let geocodeParams;
      if (isUSZip) {
        geocodeParams = { componentRestrictions: { country: 'US', postalCode: search } };
      } else {
        geocodeParams = { address: search };
      }

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(geocodeParams, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const loc = results[0].geometry.location;
          this.changeLocation(loc.lat(), loc.lng());
        }
        this.fetchResults();
      });
    }
  }

  changeLocation(lat, lng) {
    this.centerTarget.value = `(${lat},${lng})`;
    this.setPageState('location_found');
  }

  fetchResults() {
    this.updateUrl(this.formValues());

    let wretch = baseWretch().addon(QueryStringAddon)
    
    wretch.query(this.formValues(true))
          .accept('application/json')
          .get(this.data.get('url'))
          .json()
          .then(json => {
            this.setPageState(json.pageState);
            if (json.results) {
              this.resultsTarget.innerHTML = json.results;
              this.setDataLayer(this.formValues());
            }
          });
  }

  setPageState(state) {
    this.initialContentTarget.style.display = state === 'initial' ? 'block' : 'none';
    this.locationFoundContentTarget.style.display = state === 'location_found' ? 'block' : 'none';
    this.locationNotFoundContentTarget.style.display = state === 'location_not_found' ? 'block' : 'none';
  }

  updateUrl(formValues) {
    const history = createBrowserHistory();
    history.replace('/s?' + queryString.stringify(formValues));
  }

  formValues() {
    let values = {
      query: this.queryTarget.value,
      center: this.centerTarget.value,
      type: this.typeTarget.value,
      dateRange: this.dateRangeTarget.value,
      day: this.dayTarget.value,
      locationType: this.locationTypeTarget.value,
      distance: this.distanceTarget.value,
      audience: this.audienceTarget.value,
      sortBy: this.sortByTarget.value,
      city: this.cityTarget.value,
      region: this.regionTarget.value,
      postalCode: this.postalCodeTarget.value,
      country: this.countryTarget.value,
      searchType: this.searchTypeTarget.value
    };
        
    return values;
  }

  applyFilter({ field, value }) {
    this[field + 'Target'].value = value;
    this.fetchResults();
  }

  expandSearch() {
    this.publish('search:changeFilter', {
      field: "distance",
      value: "2"
    });
  }

  toggleFilters() {
    this.searchFiltersTarget.classList.toggle('tw--hidden');
  }
}
